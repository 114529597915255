import styled, { css, keyframes } from 'styled-components';

import type { LoaderProps } from './Loader.types';

const Loader = styled.div<LoaderProps>(
  ({ fullScreen, spinning, theme }) => css`
    display: block;
    background-color: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.zIndex.appBar - 2};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    text-align: center;

    ${fullScreen && 'position: fixed;'}

    ${!spinning &&
    `z-index: -1;
      opacity: 0;
      transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.5s;
    `}
  `,
);

const Warpper = styled.div`
  width: 100px;
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
`;

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto;
  text-indent: -12345px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
  animation: ${spinner} 600ms infinite linear;
`;

const Text = styled.div`
  width: 100px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 4px;
  color: #000;
`;

export const S = {
  Loader,
  Warpper,
  Spinner,
  Text,
};
