import React from 'react';

import type { LoaderProps } from './Loader.types';
import { S } from './Loader.styles';

export function Loader({ spinning, fullScreen }: LoaderProps) {
  return (
    <S.Loader spinning={spinning} fullScreen={fullScreen}>
      <S.Warpper>
        <S.Spinner />
        <S.Text>LOADING</S.Text>
      </S.Warpper>
    </S.Loader>
  );
}
