export default (channel: string) => {
  let bc;

  try {
    bc = new BroadcastChannel(channel);
  } catch (e) {
    bc = {
      onmessage: {},
      postMessage: () => undefined,
    };
  }

  return bc;
};
