async function handleGlobalError(error: unknown) {
  const { customMessage } = await import('@/components/CustomMessage');

  // `error` will be nested in some cases:
  // - Error is thrown in `setup` subscription in `src/models/app`
  if ([error?.errorCode, error?.error?.errorCode].includes(50031)) {
    // 50031: Have reached to the max limit of creating location.
    // Don't show error message for this error, we will show a paywall for this error in advance.
  } else {
    customMessage.error(error?.message ?? error?.error?.message ?? JSON.stringify(error));
  }
}

/**
 * Check if a model has already been registered before registering it
 * @param {import("dva").DvaInstance} app
 */
function registerModelIfNotExist(app, model) {
  const modelExists = app._models.some((m) => m.namespace === model.namespace);

  if (!modelExists) {
    app.model(model);
  }
}

export { handleGlobalError, registerModelIfNotExist };
