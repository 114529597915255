import lodash from 'lodash';
import qs from 'qs';

type UrlPaths = string | string[];
type PathParameters = { [key: string]: string | number };
type QueryParameters = { [key: string]: string | number | boolean | string[] | number[] | boolean[] };

export function appendSearchParams(url: string, paramKey: string, paramValue: string) {
  const urlHasSearchParams = url.includes('?');
  let searchParams = new URLSearchParams();
  let urlWithoutSearch = url;

  if (urlHasSearchParams) {
    urlWithoutSearch = url.slice(0, url.indexOf('?'));
    const search = url.slice(url.indexOf('?'));
    searchParams = new URLSearchParams(search);
  }

  searchParams.append(paramKey, paramValue);
  const newSearch = searchParams.toString();
  return `${urlWithoutSearch}?${newSearch}`;
}

export function extractSearchParams(url: string) {
  const hasSearchParams = url.includes('?');

  if (hasSearchParams) {
    const search = url.slice(url.indexOf('?'));
    return new URLSearchParams(search);
  }

  return new URLSearchParams();
}

export function extractSearchParam(url: string, paramKey: string) {
  const searchParams = extractSearchParams(url);
  return searchParams.get(paramKey);
}

/**
 * Creates a new URL by combining the specified URLs
 *
 * @param {string} baseUrl The base URL
 * @param {string} relativeUrl The relative URL
 * @returns {string} The combined URL
 */
function combineUrl(baseUrl: string, relativeUrl: string): string {
  return relativeUrl ? `${baseUrl.replace(/\/+$/, '')}/${relativeUrl.replace(/^\/+/, '')}` : baseUrl;
}

function combineMultipleUrls(baseUrl: string, relativeUrls: string[]) {
  return relativeUrls.reduce((accumulator, currentValue) => combineUrl(accumulator, currentValue), baseUrl);
}

function processPathParameters(url: string, pathParameters: PathParameters) {
  let processedUrl = url;

  for (const pathParamKey of Object.keys(pathParameters)) {
    const re = new RegExp(`:${pathParamKey}`);
    processedUrl = processedUrl.replace(re, pathParameters[pathParamKey].toString());
  }

  return processedUrl;
}

function processQueryParameters(url: string, queryParameters: QueryParameters) {
  return `${url}?${qs.stringify(queryParameters, { encodeValuesOnly: true })}`;
}

export function createUrl(
  serverAddress: string,
  urlPaths: UrlPaths,
  pathParameters: PathParameters = {},
  queryParameters: QueryParameters = {},
) {
  let url = combineMultipleUrls(serverAddress, lodash.isArray(urlPaths) ? urlPaths : [urlPaths]);

  if (!lodash.isEmpty(pathParameters)) {
    url = processPathParameters(url, pathParameters);
  }

  if (!lodash.isEmpty(queryParameters)) {
    url = processQueryParameters(url, queryParameters);
  }

  return url;
}

export function createUrlWithServerAddress(
  serverAddress: string,
  urlPaths: UrlPaths,
  pathParameters: PathParameters = {},
  queryParameters: QueryParameters = {},
) {
  return createUrl(serverAddress, urlPaths, pathParameters, queryParameters);
}

export function createUrlWithoutServerAddress(
  urlPaths: UrlPaths,
  pathParameters: PathParameters = {},
  queryParameters: QueryParameters = {},
) {
  return createUrl('', urlPaths, pathParameters, queryParameters);
}
