import { ENABLE_MFE } from '@/config';

/**
 * Compose url used by non react-router navigation, e.g.:
 * - `window.location.href = ...`
 * - `window.location.replace(...)`
 * - `<a>` tag
 * - `window.open(...)`
 */
export function composeRawRouteUrl(path: string) {
  return ENABLE_MFE
    ? process.env.NX_MFE_SUB_APP_ROUTE_PREFIX_HR == null
      ? path
      : `${process.env.NX_MFE_SUB_APP_ROUTE_PREFIX_HR}${path}`
    : `/#${path}`;
}

export function navigateFn(path: string) {
  if (ENABLE_MFE) {
    window.history.pushState(null, '', composeRawRouteUrl(path));
  } else {
    // TODO: remove hash routing after MFE phase 2 release
    window.location.replace(/^\/#\//.test(path) ? path : `/#${path}`);
  }
}
