import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enumsEn from './enums/en.json';
import enumsEs from './enums/es.json';
import defaultEn from './translations/en.json';
import defaultEs from './translations/es.json';

export const initI18n = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: true,
      resources: {
        en: {
          translation: defaultEn,
          enums: enumsEn,
        },
        es: {
          translation: defaultEs,
          enums: enumsEs,
        },
      },
      interpolation: {
        escapeValue: false,
      },
    });
};
