import type { AnyAction } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState, AppDispatchAsPromise } from '@/types/app/models';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useAppDispatch = <ReturnType = unknown>() => useDispatch<AppDispatchAsPromise<AnyAction, ReturnType>>();

/**
 * @returns A dispatch function that returns Promise only so we can chain `then`, `catch`.
 * TODO: @Bruce this is identical with useAppDispatch. Please remove it and refactor related code.
 * @deprecated prefer useAppDispatch directly
 */
function useAppDispatchAsPromise<ReturnType = unknown>() {
  const dispatch = useAppDispatch();

  /**
   * This function returns Promise only so we can chain `then`, `catch`.
   */
  const appDispatchAsPromise: AppDispatchAsPromise<AnyAction, ReturnType> = (action) =>
    dispatch(action) as Promise<ReturnType>;

  return appDispatchAsPromise;
}

function useDvaLoading() {
  const dvaLoading = useAppSelector((state) => state.loading);
  return dvaLoading;
}

export { useAppSelector, useAppDispatch, useAppDispatchAsPromise, useDvaLoading };
