import lodash from 'lodash';

import constants from '@/utils/constants';
import { createUrlWithoutServerAddress } from '@/utils/url';
import type { JobPostingFilterUrlParams } from '@/components/decoupled-job-posting/job-posting/types';
import { OPTION_ALL_VALUE } from '@/components/decoupled-job-posting/job-posting/const';

export const routePath = {
  jobPosting: {
    index: {
      default: () =>
        createUrlWithoutServerAddress('job-postings', undefined, {
          location_id: [OPTION_ALL_VALUE],
          department_id: [OPTION_ALL_VALUE],
          position_template_id: [OPTION_ALL_VALUE],
          hiring_process_template_id: [OPTION_ALL_VALUE],
          access_with_status_code: [OPTION_ALL_VALUE],
        }),
      withParams: (params: JobPostingFilterUrlParams) =>
        createUrlWithoutServerAddress(
          'job-postings',
          undefined,
          lodash.pickBy(params, (param) => param != null),
        ),
    },
  },
  positionTemplate: {
    createPositionTemplate: () =>
      createUrlWithoutServerAddress('company', undefined, {
        currentPanel: constants.POSITION,
        create_position_modal_visible: true,
      }),
  },
  hiringProcessTemplate: {
    index: () =>
      createUrlWithoutServerAddress('company', undefined, { currentPanel: constants.HIRING_PROCESS_TEMPLATE }),
    createHiringProcessTemplate: {
      router: () => createUrlWithoutServerAddress(['create-hiring-process-template', ':tab_key']),
      tab: (tabKey: string) =>
        createUrlWithoutServerAddress(['create-hiring-process-template', ':tab_key'], { tab_key: tabKey }),
      tabDefault: () =>
        createUrlWithoutServerAddress(['create-hiring-process-template', ':tab_key'], { tab_key: 'stages' }),
    },
    editHiringProcessTemplate: {
      router: () => createUrlWithoutServerAddress(['edit-hiring-process-template', ':id', ':tab_key']),
      tab: (hiringProcessTemplateId: number, tabKey: string) =>
        createUrlWithoutServerAddress(['edit-hiring-process-template', ':id', ':tab_key'], {
          id: hiringProcessTemplateId,
          tab_key: tabKey,
        }),
      tabDefault: (hiringProcessTemplateId: number) =>
        createUrlWithoutServerAddress(['edit-hiring-process-template', ':id', ':tab_key'], {
          id: hiringProcessTemplateId,
          tab_key: 'stages',
        }),
    },
  },
  employeeOnboarding: {
    standaloneStartOnboarding: '/start-onboarding',
    standaloneChangeOnboardingProcess: '/change-onboarding-process',
    standaloneOnboardingTasks: {
      routeMatchPath: '/onboarding-tasks/:processDigestKey',
      router: (processDigestKey: string) => `/onboarding-tasks/${processDigestKey}`,
    },
  },
  employeeRecords: {
    list: '/employee-records/#/list',
    details: (employeeRecordId: string | number) =>
      `/employee-records/#/profile/${employeeRecordId}?include=open_issues`,
    detailsWithIssuesModalOpened: (employeeRecordId: string | number) =>
      `/employee-records/#/profile/${employeeRecordId}?include=open_issues&issue_modal_visible=true`,
  },
};
