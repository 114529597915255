import type { DvaInstance } from 'dva';
import type { History } from 'history';
import React, { Suspense } from 'react';
import { routerRedux } from 'dva';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { WsThemeProvider, CatchAllErrorBoundary, ErrorPage } from '@helloworld1812/app-global-components';

import { ENABLE_MFE } from '@/config';
import { routePath } from '@/helpers/route';
import { FullHeightWrapperWithLoader } from '@/components/common/full-height-wrapper-with-loader';
import { Loader } from '@/components/Loader';
import MultitabDetector from '@/components/app/multitab-detector';

import HiringPaywallPage from './routes/hiring-paywall';
import { LazyAdminDashboard } from './routes/admin-dashboard';

const { ConnectedRouter } = routerRedux;

const Dashboard = React.lazy(() => import(/* webpackChunkName: "routes--dashboard" */ './routes/dashboard'));
const SADashboard = React.lazy(() => import(/* webpackChunkName: "routes--sa-dashboard" */ './routes/sa-dashboard'));
const Account = React.lazy(() => import(/* webpackChunkName: "routes--account" */ './routes/account'));
const Company = React.lazy(() => import(/* webpackChunkName: "routes--company" */ './routes/company'));
const Referral = React.lazy(() => import(/* webpackChunkName: "routes--referral" */ './routes/company/Referral'));
const Applicants = React.lazy(() => import(/* webpackChunkName: "routes--applicants" */ './routes/applicants'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "routes--login" */ './routes/login'));
const SetupPassword = React.lazy(
  () => import(/* webpackChunkName: "routes--setup--password" */ './routes/setup/password'),
);

const JobPostingTemplateDetail = React.lazy(async () => {
  const { PositionTemplateDetail } = await import(
    /* webpackChunkName: "routes--position-template-detail" */ './routes/decoupled-job-posting/position/position-template-detail'
  );
  return { default: PositionTemplateDetail };
});

const JobPostings = React.lazy(async () => {
  const { JobPostingIndexPage } = await import(
    /* webpackChunkName: "routes--job-posting-index-page" */ './routes/decoupled-job-posting/job-posting/job-posting-index-page'
  );
  return { default: JobPostingIndexPage };
});

const JobPostingDetail = React.lazy(async () => {
  const { JobPostingDetailPage } = await import(
    /* webpackChunkName: "routes--job-posting-detail" */ './routes/decoupled-job-posting/job-posting/job-posting-detail-page'
  );
  return { default: JobPostingDetailPage };
});

const HiringProcessTemplateCreateRoute = React.lazy(
  () =>
    import(
      /* webpackChunkName: "routes--create-hiring-process-template" */ './routes/hiring-process-template-create-route'
    ),
);

const HiringProcessTemplateEditRoute = HiringProcessTemplateCreateRoute;

const Schedule = React.lazy(
  () => import(/* webpackChunkName: "routes--schedule--Entry" */ './routes/improved-schedule'),
);

const AllApplicants = React.lazy(
  () => import(/* webpackChunkName: "routes--applicants--AllApplicants" */ './routes/applicants/AllApplicants'),
);
const RegistCheckr = React.lazy(
  () => import(/* webpackChunkName: "routes--integration--RegistCheckr" */ './routes/integration/RegistCheckr'),
);
const Messages = React.lazy(() => import(/* webpackChunkName: "routes--messages" */ './routes/messages'));
const ForgotPasswordPage = React.lazy(
  () => import(/* webpackChunkName: "routes--forgot-password" */ './routes/forgot-password'),
);
const ResetPasswordPage = React.lazy(async () => {
  const { ResetPassword } = await import(/* webpackChunkName: "routes--reset-password" */ './routes/reset-password');
  return { default: ResetPassword };
});
const UserOnBoarding = React.lazy(
  () => import(/* webpackChunkName: "routes--user-onboarding" */ './routes/user-onboarding'),
);
const EmployeeOnboarding = React.lazy(
  () => import(/* webpackChunkName: "routes--employee-onboarding" */ './routes/employee-onboarding'),
);
const EmployeeOnboardingTasks = React.lazy(
  () =>
    import(
      /* webpackChunkName: "routes--employee-onboarding--view-onboarding" */ './routes/employee-onboarding/view-onboarding'
    ),
);
const StandaloneStartOnboarding = React.lazy(async () => {
  const { StandaloneStartOnboarding: StandaloneStartOnboardingComp } = await import(
    /* webpackChunkName: "routes--employee-onboarding--standalone-start-onboarding" */ './routes/employee-onboarding/standalone-start-onboarding'
  );
  return { default: StandaloneStartOnboardingComp };
});
const StandaloneChangeOnboardingProcess = React.lazy(async () => {
  const { StandaloneChangeOnboardingProcess: StandaloneChangeOnboardingProcessComp } = await import(
    /* webpackChunkName: "routes--employee-onboarding--standalone-change-onboarding-process" */ './routes/employee-onboarding/standalone-change-onboarding-process'
  );
  return { default: StandaloneChangeOnboardingProcessComp };
});
const ADP = React.lazy(() => import(/* webpackChunkName: "routes--sso--adp" */ './routes/sso/adp'));
const ADPRedirect = React.lazy(
  () => import(/* webpackChunkName: "routes--sso--adp--redirect" */ './routes/sso/adp/redirect'),
);
const JobTargetCallback = React.lazy(
  () => import(/* webpackChunkName: "routes--job-target--Callback" */ './routes/job-target/Callback'),
);
const RedirectPage = React.lazy(() => import(/* webpackChunkName: "routes--redirect" */ './routes/redirect'));
const SelectCompany = React.lazy(
  () => import(/* webpackChunkName: "routes--select-company" */ './routes/select-company'),
);

const SourcingToolsPage = React.lazy(() => import('./routes/sourcing-tools'));
const Support = React.lazy(() => import('./routes/support'));
const PayrollPaywall = React.lazy(() => import(/* webpackChunkName: "routes--ErrorPage" */ './routes/payroll-paywall'));

function RouterConfig({ app, history }: { app: DvaInstance; history: History }) {
  const App = React.lazy(async () => {
    const { registerAllModels } = await import(/* webpackChunkName: "models" */ '@/models');
    registerAllModels(app);
    return import(/* webpackChunkName: "routes--App" */ './routes/App');
  });

  const Analytics = React.lazy(async () => {
    await Promise.all([
      app._store.dispatch({ type: 'app/fetchUserInfo' }),
      app._store.dispatch({ type: 'permission/fetchAvailableAccountPermission' }),
    ]);
    return import(/* webpackChunkName: "routes--analytics" */ './routes/analytics');
  });

  const Profile = React.lazy(async () => {
    await Promise.all([
      app._store.dispatch({ type: 'app/fetchGrowthbookFeaturesData' }),
      app._store.dispatch({ type: 'app/fetchUserInfo' }),
    ]);

    const { ApplicantsProfileIndexContainer } = await import(
      /* webpackChunkName: "routes--profile" */ './routes/profile'
    );

    return { default: ApplicantsProfileIndexContainer };
  });

  return (
    // WARNING: DO NOT add an component that require or would fetch user info at this place.
    // Any component that requires or would actively fetch a logged-in user should be placed in
    // src/Routes/app.tsx, https://github.com/helloworld1812/workstream-hr/blob/c02b5cd0fe64fd7a5065d41ccdc9b9b882133560/src/routes/App.tsx#L161C1-L161C1
    // as this is where the code ensures that a user exists.
    <WsThemeProvider>
      <CatchAllErrorBoundary>
        <ConnectedRouter history={history}>
          <CompatRouter>
            <Suspense fallback={<FullHeightWrapperWithLoader spinning fullScreen={!ENABLE_MFE} />}>
              <App>
                <Suspense fallback={<Loader spinning />}>
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/:brand" component={SADashboard} />
                    <Route exact path="/positions" render={() => <Redirect to="/job-postings" />} />
                    <Route
                      exact
                      path="/job-posting-template/:position_template_id"
                      component={JobPostingTemplateDetail}
                    />
                    <Route exact path="/job-postings" component={JobPostings} />
                    <Route exact path="/job-postings/:digestkey" component={JobPostingDetail} />
                    <Route
                      exact
                      path={routePath.hiringProcessTemplate.createHiringProcessTemplate.router()}
                      component={HiringProcessTemplateCreateRoute}
                    />
                    <Route
                      exact
                      path={routePath.hiringProcessTemplate.editHiringProcessTemplate.router()}
                      component={HiringProcessTemplateEditRoute}
                    />
                    <Route exact path="/success-login" component={LoginPage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                    <Route exact path="/reset-password/:token" component={ResetPasswordPage} />
                    <Route exact path="/applicants/:location/:position/:digestkey" component={Applicants} />
                    <Route exact path="/applicants" component={AllApplicants} />
                    <Route exact path="/applicants/all-applicants" component={AllApplicants} />
                    <Route exact path="/applicants/inactive-applicants" component={AllApplicants} />
                    <Route exact path="/messages" component={Messages} />
                    <Route exact path="/applicant/:id" component={Profile} />
                    <Route exact path="/my-profile" component={Account} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/account/:id" component={Account} />
                    <Route exact path="/company" component={Company} />
                    <Route exact path="/company/dashboard" component={LazyAdminDashboard} />
                    <Route exact path="/referral" component={Referral} />
                    <Route path="/analytics" component={Analytics} />
                    <Route exact path="/integration/checkr" component={RegistCheckr} />
                    <Route exact path="/schedule" component={Schedule} />
                    <Route exact path="/set-password/:token" component={SetupPassword} />
                    <Route exact path="/user-onboarding" component={UserOnBoarding} />
                    <Redirect
                      exact
                      from="/onboarding/processes"
                      to={{
                        pathname: '/admin/onboarding/processes',
                        search: history?.location?.search,
                      }}
                    />
                    <Route
                      exact
                      path={routePath.employeeOnboarding.standaloneOnboardingTasks.routeMatchPath}
                      component={EmployeeOnboardingTasks}
                    />
                    <Route
                      exact
                      path={routePath.employeeOnboarding.standaloneStartOnboarding}
                      component={StandaloneStartOnboarding}
                    />
                    <Route
                      exact
                      path={routePath.employeeOnboarding.standaloneChangeOnboardingProcess}
                      component={StandaloneChangeOnboardingProcess}
                    />
                    <Route exact path="/onboarding" component={EmployeeOnboarding} />
                    <Route exact path="/admin/onboarding" component={EmployeeOnboarding} />
                    <Route path="/admin/onboarding/:page" component={EmployeeOnboarding} />
                    <Route path="/onboarding/:page" component={EmployeeOnboarding} />

                    <Redirect
                      from="/employee-onboarding"
                      to={{
                        pathname: '/onboarding',
                        search: history?.location?.search,
                      }}
                    />
                    <Redirect
                      from="/employee-onboarding/:page"
                      to={{
                        pathname: '/onboarding/:page',
                        search: history?.location?.search,
                      }}
                    />
                    <Route exact path="/sso/adp" component={ADP} />
                    <Route exact path="/adp/callback" component={ADP} />
                    <Route exact path="/adp/redirect" component={ADPRedirect} />
                    <Route exact path="/job-target-callback" component={JobTargetCallback} />
                    <Route exact path="/select-company" component={SelectCompany} />
                    <Route exact path="/sourcing-tools" component={SourcingToolsPage} />
                    <Route exact path="/support" component={Support} />
                    <Route path="/redirect" component={RedirectPage} />

                    <Route exact path="/payroll-paywall" component={PayrollPaywall} />
                    <Route exact path="/hiring-paywall" component={HiringPaywallPage} />
                    <Route component={ErrorPage} />
                  </Switch>

                  <MultitabDetector />
                </Suspense>
              </App>
            </Suspense>
          </CompatRouter>
        </ConnectedRouter>
      </CatchAllErrorBoundary>
    </WsThemeProvider>
  );
}

export default RouterConfig;
