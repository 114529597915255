import styled from 'styled-components';
import { Popover } from '@helloworld1812/hr-ui';
import { Text } from '@helloworld1812/ws-components';

import { HiringPaywallDisabledStyle } from './hiring-paywall-wrapper.types';

export const S = {
  HoverCardWrapper: styled.div<{ block: boolean; disabledStyle: HiringPaywallDisabledStyle }>`
    height: fit-content;
    position: relative;
    width: ${(props) => (props.block ? '100%' : 'fit-content')};
    opacity: ${(props) => (props.disabledStyle === 'opacity' ? 0.5 : 'unset')};
  `,
  Popover: styled(Popover)`
    width: 400px;
    padding: 20px;
    font-size: 14px;
  `,
  LinkText: styled(Text)`
    cursor: pointer;
  `,
  GrayOutWrapper: styled.div`
    cursor: not-allowed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
};
