import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BroadcastChannel from '@/utils/broadcast-channel';

const MultitabDetector = () => {
  const routerLocation = useLocation();

  useEffect(
    () => {
      const bc = BroadcastChannel('multi-login-detector');
      bc.onmessage = () => {
        if (['/login', '/select-company'].includes(routerLocation.pathname)) {
          // Kick them in to dashboard if they are in login page or select company page
          window.location.replace('/');
        } else {
          window.location.replace(routerLocation.pathname);
          window.location.reload();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
};

export default MultitabDetector;
