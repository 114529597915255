import constants from '@/utils/constants';
import { useAppSelector } from '@/hooks/models';

type FunctionModule = typeof constants.FUNCTION_MODULES;
export type FunctionModuleKey = FunctionModule[keyof FunctionModule];

export function useHasFunctionModule(functionModuleName: FunctionModuleKey): boolean {
  const hasFunctionModule = useAppSelector(
    (state) => state.app?.functionModules?.includes(functionModuleName) ?? false,
  );
  return hasFunctionModule;
}
